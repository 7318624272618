import axios from '@/libs/axios'

export function reqGetTrainings(payload) {
  return axios.get('api/admin/trainings', { params: payload })
}
export function reqGetTraining(id) {
  return axios.get(`api/admin/trainings/${id}`)
}
export function reqCreateTraining(payload) {
  return axios.post('api/admin/trainings', payload)
}
export function reqUpdateTraining(id, payload) {
  return axios.post(`api/admin/trainings/${id}?_method=PATCH`, payload)
}
export function reqDeleteTraining(id) {
  return axios.delete(`api/admin/trainings/${id}`)
}
