import axios from '@/libs/axios'

export function reqGetTrainers(payload) {
  return axios.get('api/admin/trainers', { params: payload })
}
export function reqGetTrainer(id) {
  return axios.get(`api/admin/trainers/${id}`)
}
export function reqCreateTrainer(payload) {
  return axios.post('api/admin/trainers', payload)
}
export function reqUpdateTrainer(id, payload) {
  return axios.post(`api/admin/trainers/${id}?_method=PATCH`, payload)
}
export function reqDeleteTrainer(id) {
  return axios.delete(`api/admin/trainers/${id}`)
}
