<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="card mb-1" title="Data Pelatihan">
      <!-- Title -->
      <div class="d-flex pb-2 border-bottom">
        <b-avatar :src="blobImage" :text="avatarText('Kelas')" :variant="`light-primary`" size="104px" rounded />
        <div class="ml-1 d-flex flex-column justify-content-center">
          <div class="d-flex mb-1">
            <input ref="file" accept="image/png, image/jpg, image/jpeg" type="file" style="display: none"
              @change="onFileChange" />
            <b-button variant="outline-primary small-gap-right" @click="$refs.file.click()">
              Upload Foto Baru
            </b-button>
            <b-button variant="secondary" @click="blobImage = ''">
              Reset
            </b-button>
          </div>
          <span>Upload foto pelatihan format jpg/png maks. 800kb</span>
          <div v-if="formErrors['image']" class="d-block invalid-feedback">
            <div v-for="(item, index) in formErrors['image']" :key="index">
              {{ item }}
            </div>
          </div>
        </div>
      </div>

      <!-- Classroom Config -->
      <div class="mt-2">
        <b-row>
          <b-col sm="12">
            <label class="d-block">Banner</label>
            <div class="image-upload-container">
              <b-card v-for="(item, index) in formData.banners" :key="index" no-body class="image-upload-card text-center"
                border-variant="secondary">
                <div class="image-upload">
                  <img :src="item.url" alt="" />
                </div>
                <b-button size="sm" variant="danger" class="m-0 btn-sm remove-button" type="button"
                  @click="removeSponsor(index)">
                  <span class="m-auto">x</span>
                </b-button>
              </b-card>
              <b-card no-body class="image-upload-card text-center" border-variant="secondary"
                @click="$refs.fileSponsor.click()">
                <input ref="fileSponsor" type="file" accept=".png, .jpg, .jpeg" class="d-none" @change="addSponsor" />
                <i class="ti ti-plus ti" />
              </b-card>
            </div>
            <div v-if="formErrors['banners']" class="d-block invalid-feedback">
              <div v-for="(item, index) in formErrors['banners']" :key="index">
                {{ item }}
              </div>
            </div>
          </b-col>
          <b-col lg="12" class="mb-1">
            <b-form-group v-slot="{ ariaDescribedby }" label="Status" :state="formErrors.status ? false : null"
              :invalid-feedback="formErrors.status ? formErrors.status[0] : ''">
              <b-form-radio-group id="radio-group-status" v-model="formData.status" :options="[
                { value: 'Belum Mulai', text: 'Belum Mulai' },
                { value: 'Pendaftaran', text: 'Masa Pendaftaran' },
                { value: 'Sedang Berlangsung', text: 'Sedang Berlangsung' },
                { value: 'Selesai', text: 'Selesai' },
              ]" :aria-describedby="ariaDescribedby" name="radio-option-status" />
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Nama</label>
            <b-form-input id="classroom-name" v-model="formData.title" placeholder="Masukkan Nama Pelatihan"
              :state="formErrors.title ? false : null" />
            <b-form-invalid-feedback v-if="formErrors.title">
              {{ formErrors.title[0] }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Gratis?</label>
            <div class="d-flex align-items-center">
              <b-form-checkbox v-model="formData.is_free" name="check-button" switch inline class="mr-0" :value="1" />
              <span class="line-height-small"> Ya </span>
            </div>
            <small id="emailHelp" class="form-text text-muted">Jika diaktifkan maka user tidak perlu membayar untuk
              bergabung di pelatihan ini</small>
          </b-col>
          <b-col md="6" class="mb-1" v-if="!formData.is_free">
            <label class="d-block">Harga</label>
            <b-form-input v-model="formData.price" type="number" placeholder="Masukkan Harga"
              :state="formErrors.price ? false : null" />
            <b-form-invalid-feedback v-if="formErrors.price">
              {{ formErrors.price[0] }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col md="6" class="mb-1" v-if="!formData.is_free">
            <label class="d-block">Diskon %</label>
            <b-form-input v-model="formData.discount" type="number" placeholder="Masukkan Diskon" max="100"
              :state="formErrors.discount ? false : null" />
            <b-form-invalid-feedback v-if="formErrors.discount">
              {{ formErrors.discount[0] }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col lg="6" class="mb-1">
            <label class="d-block">Penanggung Jawab</label>
            <v-select v-model="formData.responsible_id" :reduce="(trainer) => trainer.id" :options="staffs"
              label="name" />
            <span class="text-danger" v-if="formErrors.responsible_id">{{ formErrors.responsible_id[0] }}</span>
          </b-col>
          <b-col lg="6" class="mb-1">
            <label class="d-block">Pelatih</label>
            <v-select v-model="formData.trainer_id" :reduce="(trainer) => trainer.id" :options="trainers" label="name" />
            <span class="text-danger" v-if="formErrors.trainer_id">{{ formErrors.trainer_id[0] }}</span>
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Deskripsi</label>
            <b-form-textarea v-model="formData.description" placeholder="Deskripsi Pelatihan" rows="3" no-resize
              :state="formErrors.description ? false : null" />
            <b-form-invalid-feedback v-if="formErrors.description">
              {{ formErrors.description[0] }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col lg="6" class="mb-1">
            <b-form-group v-slot="{ ariaDescribedby }" label="Level" :state="formErrors.level ? false : null"
              :invalid-feedback="formErrors.level ? formErrors.level[0] : ''">
              <b-form-radio-group id="radio-group-1" v-model="formData.level" :options="[
                { value: 1, text: 'Basic' },
                { value: 2, text: 'Intermediate' },
                { value: 3, text: 'Advance' },
              ]" :aria-describedby="ariaDescribedby" name="radio-options" />
            </b-form-group>
          </b-col>

          <b-col md="6" class="mb-1">
            <label class="d-block">Total JP</label>
            <b-form-input v-model="formData.jp" type="number" placeholder="Masukkan total JP"
              :state="formErrors.jp ? false : null" />
            <b-form-invalid-feedback v-if="formErrors.jp">
              {{ formErrors.jp[0] }}
            </b-form-invalid-feedback>
          </b-col>

          <!-- Start Date -->
          <b-col md="6">
            <label class="d-block">Tanggal Mulai </label>
            <b-form-group :state="formErrors.start_at ? false : null">
              <flat-pickr v-model="formData.start_at" class="form-control" :class="{
                'is-invalid': formErrors.start_at,
              }" :config="{
  altInput: true,
  altFormat: 'j F Y (H:i)',
  dateFormat: 'Y-m-d H:i',
  minDate: 'today',
  enableTime: true,
  time_24hr: true,
}" />
            </b-form-group>
            <div v-if="formErrors.start_at" class="invalid-feedback">
              {{ formErrors.start_at[0] }}
            </div>
          </b-col>

          <!-- Start Date -->
          <b-col md="6">
            <label class="d-block">Tanggal Berakhir </label>
            <b-form-group :state="formErrors.end_at ? false : null">
              <flat-pickr v-model="formData.end_at" class="form-control" :class="{
                'is-invalid': formErrors.end_at ? false : false,
              }" :config="{
  altInput: true,
  altFormat: 'j F Y (H:i)',
  dateFormat: 'Y-m-d H:i',
  minDate: 'today',
  enableTime: true,
  time_24hr: true,
}" />
            </b-form-group>
            <div v-if="formErrors.end_at" class="invalid-feedback">
              {{ formErrors.end_at[0] }}
            </div>
          </b-col>

          <!-- technical skills -->
          <b-col md="12">
            <label class="d-block">Technical Skills </label>
            <!-- <b-form-input v-model="formData.technical_skills[index]" type="text" placeholder="Masukkan skill teknis"
              :state="formErrors.technical_skills ? false : null" v-for="(skill, index) in formData.technical_skills"
              :key="index" /> -->
            <b-input-group :state="formErrors.technical_skills ? false : null" class="mb-1 is-invalid" v-for="(skill, index) in formData.technical_skills" :key="index">
              <b-form-input v-model="formData.technical_skills[index]" type="text" :placeholder="`Masukkan skill teknis ${index + 1}`"
              :state="formErrors.technical_skills ? false : null" 
               />
              <b-input-group-append>
                <b-button variant="danger" @click="formData.technical_skills.splice(index, 1)" v-if="index >= 1">Hapus</b-button>
                <b-button variant="success" @click="formData.technical_skills.push('')" v-if="index === formData.technical_skills.length - 1">Tambah</b-button>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback v-if="formErrors.technical_skills">
              {{ formErrors.technical_skills[0] }}
            </b-form-invalid-feedback>
          </b-col>


          <b-col lg="12" class="mb-1">
            <label class="d-block">Dapat Dilihat User?</label>
            <div class="d-flex align-items-center">
              <b-form-checkbox v-model="formData.is_visible" name="check-button" switch inline class="mr-0" :value="1" />
              <span class="line-height-small"> Ya </span>
            </div>
            <small id="emailHelp" class="form-text text-muted">Jika diaktifkan maka semua User dapat melihat pelatihan
              ini</small>
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Gunakan kode?</label>
            <div class="d-flex align-items-center">
              <b-form-checkbox v-model="useCode" name="check-button" switch inline class="mr-0" :value="1" />
              <span class="line-height-small"> Ya </span>
            </div>
            <small id="emailHelp" class="form-text text-muted">Jika kode ditambahkan maka hanya User yang mengetahui kode
              tersebut yang dapat bergabung</small>
          </b-col>
          <b-col lg="12" class="mb-1" v-if="useCode">
            <b-form-group v-slot="{ ariaDescribedby }" label="Kode" :state="formErrors.code ? false : null"
              :invalid-feedback="formErrors.code ? formErrors.code[0] : ''">
              <b-form-input :aria-describedby="ariaDescribedby" v-model="formData.code" placeholder="Masukkan Kode"
                :state="formErrors.code ? false : null" />
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Perlu Persetujuan?</label>
            <div class="d-flex align-items-center">
              <b-form-checkbox v-model="formData.need_approval" name="check-button" switch inline class="mr-0"
                :value="1" />
              <span class="line-height-small"> Ya </span>
            </div>
            <small id="emailHelp" class="form-text text-muted">Jika diaktifkan maka Admin harus melakukan persetujuan
              pendaftaran</small>
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Ada Persyaratan?</label>
            <div class="d-flex align-items-center">
              <b-form-checkbox v-model="formData.need_requirement" name="check-button" switch inline class="mr-0"
                :value="1" />
              <span class="line-height-small"> Ya </span>
            </div>
            <small id="emailHelp" class="form-text text-muted">Persyaratan dokumen atau persyartan telah mengikuti
              pelatihan tertentu</small>
          </b-col>

          <b-col v-if="formData.need_requirement" lg="12" class="mb-1">
            <label class="d-block">Deskripsi Persyaratan Unggah Dokumen</label>
            <b-form-textarea v-model="formData.requirement_description" placeholder="Deskripsi Persyaratan Pelatihan"
              rows="3" no-resize :state="formErrors.requirement_description ? false : null" />
            <b-form-text id="desc-help-block">
              Jika diisi maka peserta pelatihan harus mengunggah file yang tertera dideskripsi ini
            </b-form-text>
            <b-form-invalid-feedback v-if="formErrors.requirement_description">
              {{ formErrors.requirement_description[0] }}
            </b-form-invalid-feedback>
          </b-col>

          <b-col v-if="formData.need_requirement" lg="12" class="mb-1">
            <label class="d-block">Persyaratan Pelatihan Sebelumnya</label>
            <v-select v-model="formData.requirement_trainings" multiple :reduce="(training) => training.id"
              :options="trainings" label="title" :class="{
                'is-invalid': formErrors.requirement_trainings,
              }" />
            <b-form-text id="req-help-block">
              Jika diisi maka peserta pelatihan harus memiliki sertifikat pelatihan yang disebutkan
            </b-form-text>
            <div v-if="formErrors.requirement_trainings" class="invalid-feedback d-block">
              {{ formErrors.requirement_trainings[0] }}
            </div>
          </b-col>
        </b-row>
        <div>
          <b-button variant="primary" class="float-right" @click="submitForm">
            <div class="d-flex align-items-center">
              <b-spinner v-if="isLoading" class="mr-1" variant="light" small />
              Simpan
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormText,
  BFormRadioGroup,
  BSpinner,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { checkAuthorizeRole } from "@/auth/utils";

// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";

import { reqGetTrainings, reqCreateTraining } from "@/api/admin/training";
import { reqGetTrainers } from "@/api/admin/trainer";
import { reqGetStaffs } from "@/api/admin/staff";

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BFormRadioGroup,
    BFormText,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      isLoading: false,
      isOpenClass: false,
      blobImage: "",
      formErrors: {},
      formData: {
        is_free: 1,
        price: 0,
        discount: 0,
        title: "",
        image: null,
        description: "",
        level: "",
        start_at: "",
        end_at: "",
        is_visible: 0,
        status: "Belum Dimulai",
        code: "",
        need_approval: 1,
        banners: [],
        need_requirement: 0,
        requirement_trainings: [],
        technical_skills: [''],
        trainer_id: "",
        responsible_id: ""
      },
      useCode: false,
      trainings: [],
      trainers: [],
      staffs: []

    };
  },
  mounted() {
    this.getTrainings();
    this.getTrainers();
    this.getStaffs();
  },
  methods: {
    avatarText,
    getTrainers() {
      reqGetTrainers({ per_page: 999 }).then((response) => {
        this.trainers = response.data.data.data;
      });
    },
    getStaffs() {
      reqGetStaffs({ per_page: 999 }).then((response) => {
        this.staffs = response.data.data.data;
      });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.formData.image = file;
      this.blobImage = URL.createObjectURL(file);
    },
    getTrainings() {
      reqGetTrainings({ per_page: 999 }).then((response) => {
        this.trainings = response.data.data.data;
      });
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      });
    },
    validateForm() {
      const isValid = true;
      return isValid;
    },
    async submitForm() {
      this.isLoading = true;
      if (this.validateForm()) {
        const data = new FormData();
        for (const i in this.formData) {
          if (i == "banners") {
            this.formData.banners.forEach((banner, index) => {
              data.append(`banners[${index}]`, banner.file);
            });
          } else if (i == "is_visible" || i == "need_requirement" || i == "need_approval") {
            data.append(
              i,
              this.formData[i] == true || this.formData[i] == "true" ? 1 : 0
            );
          } else if (i == "requirement_trainings") {
            if (this.formData.requirement_trainings.length) {
              this.formData.requirement_trainings.forEach((element, index) => {
                data.append(`requirement_trainings[${index}]`, element);
              });
            }
          } else if (i == "code") {
            if (this.useCode && this.formData[i]) {
              data.append(i, this.formData[i])
            }
          } else if (i == "technical_skills") {
            this.formData.technical_skills.forEach((skill, index) => {
              if (skill)
              data.append(`technical_skills[${index}]`, skill);
            });
          } else if (this.formData[i]) {
            data.append(i, this.formData[i]);
          }
        }
        // create classroom
        await reqCreateTraining(data)
          .then((response) => {
            // stop loading
            this.isLoading = false;

            // show success toast
            this.showToast(
              "success",
              "Check",
              "Berhasil",
              "Pelatihan berhasil dibuat"
            );

            // redirect to classroom detail
            this.$router.push({
              name: checkAuthorizeRole("trainings.show"),
              params: { trainingId: response.data.data.id },
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.formErrors = error.response.data.errors;
            this.showToast(
              "danger",
              "Check",
              "Gagal",
              error.response.data.message || "Pelatihan gagal dibuat"
            );
          });
      }
    },
    addSponsor(e) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      this.formData.banners.push({
        file,
        url,
      });
    },
    removeSponsor(index) {
      this.formData.banners.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.line-height-small {
  line-height: 1.5rem;
}

.small-gap-right {
  margin-right: 8px;
}

.small-gap-top {
  margin-top: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.image-upload-container {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  overflow-x: auto;
  position: relative;
}

.image-upload-container .image-upload-card {
  margin: 15px;
  margin-left: 0px;
  min-width: 200px;
  min-height: 100px;
  width: 200px;
  height: 100px;
  cursor: pointer;
  object-fit: fill;
  padding: 1px;
  background: white;
}

.image-upload-container .image-upload-card i {
  margin: auto;
  font-size: 40px;
}

.image-upload-container .image-upload-card .image-upload {
  width: 100%;
  height: 100%;
  object-fit: fill;
  overflow: hidden;
}

.image-upload-container .image-upload-card .image-upload img {
  width: 100%;
  height: 100%;
}

.remove-button {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  padding: 0px;
}
</style>
